import type { RadioTowerGuard } from '../types/test';
import type CustomContext from '../utils/CustomContext';

export default (
  data: RadioTowerGuard,
  ctx: CustomContext,
) => {
  data.BossPaths.forEach((path) => {
    const { Path } = path;

    const positions = Path.map((pos) => ({
      x: pos.X,
      y: pos.Y,
      z: pos.Z,
    }));

    // ctx.drawLine({
    //   positions,
    //   color: 'black',
    //   width: 2,
    // });

    // if (path.Mode === 1) {
    //   // means its a loop
    //   ctx.drawLine({
    //     positions: [
    //       positions[positions.length - 1],
    //       positions[0],
    //     ],
    //     color: 'black',
    //     width: 2,
    //   });
    // }

    positions.forEach((pos) => {
      // ctx.drawCircle({
      //   position: pos,
      //   radius: 20,
      //   color: 'blue',
      //   width: 2,
      //   fill: true,
      // });
      ctx.drawRect({
        position: pos,
        size: {
          x: 5,
          y: 5,
        },
        fill: true,
        color: 'blue',
      });
    });
  });
};
