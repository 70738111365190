import type {
  HorizontalAlign, VerticalAlign,
  MapManifest,
  Position,
  Rotation,
  Size,
} from '../types/CustomContext';
import type CustomContext from './CustomContext';

const coloredImageCache: Record<string, HTMLCanvasElement> = {};
const imageCache: Record<string, HTMLImageElement | HTMLCanvasElement> = {};

export type ImageThingy = HTMLImageElement | HTMLCanvasElement;

export const getImage = (path: string) => {
  if (imageCache[path]) {
    return imageCache[path];
  }

  const image = new Image();

  image.src = path;

  imageCache[path] = image;

  image.onerror = () => {
    image.hidden = true;
  };

  return image;
};

export const getCenterBasedOnPercent = (
  v1: number,
  v2: number,
  percent: number,
) => (v2 - v1) * percent + v1;

export const getPosBasedOnPercent = (
  pos1: Position,
  pos2: Position,
  percent: number,
) => ({
  x: getCenterBasedOnPercent(pos1.x, pos2.x, percent),
  y: getCenterBasedOnPercent(pos1.y, pos2.y, percent),
});

export const getCanvasPos = (
  vector3: Position,
  ctx: CustomContext,
): Position => {
  const x = ctx.mapWidth / 2 + vector3.y;
  const y = ctx.mapHeight - (ctx.mapHeight / 2 + vector3.x);

  const canvasSize = Math.min(ctx.width, ctx.height);

  return {
    x: (x / ctx.mapWidth) * canvasSize,
    y: (y / ctx.mapHeight) * canvasSize,
  };
};

export const getMapPos = (vector2: Position, ctx: CustomContext): Position => {
  const canvasSize = Math.min(ctx.width, ctx.height);

  const tempX = (vector2.x / canvasSize) * ctx.mapWidth;
  const tempY = (vector2.y / canvasSize) * ctx.mapHeight;

  const x = ctx.mapHeight - tempY - ctx.mapHeight / 2;
  const y = tempX - ctx.mapWidth / 2;

  return {
    x,
    y,
  };
};

export const getMapRotation = (rot: Rotation) => (rot.yaw * Math.PI) / 180;

export const getCanvasScale = (
  input: number,
  canWidth: number,
  mapWidth: number,
) => (input / mapWidth) * canWidth;

export const getMapScale = (
  input: number,
  canvasWidth: number,
  mapWidth: number,
) => (input / canvasWidth) * mapWidth;

export const getEventPos = (
  event: {
    clientX: number;
    clientY: number;
  },
  canvas: HTMLCanvasElement,
) => {
  const x = event.clientX;
  const y = event.clientY;

  const canvasPos = canvas.getClientRects()[0];

  return {
    x: x - canvasPos.x,
    y: y - canvasPos.y,
  };
};

export const getColoredImage = (image: ImageThingy, color: string, cacheKey?: string) => {
  const theCacheKey = `${image instanceof HTMLImageElement ? image.src : cacheKey || ''}-${color}`;

  if (coloredImageCache[theCacheKey]) {
    return coloredImageCache[theCacheKey];
  }

  const canvas = document.createElement('canvas');

  if (image instanceof HTMLImageElement && !image.complete) {
    return canvas;
  }

  canvas.height = image.height;
  canvas.width = image.width;

  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return canvas;
  }

  ctx.drawImage(image, 0, 0, image.height, image.width);

  ctx.globalCompositeOperation = 'source-in';

  ctx.fillStyle = color;

  ctx.fillRect(0, 0, image.height, image.width);
  coloredImageCache[theCacheKey] = canvas;

  return canvas;
};

export const getRotBasedOnPercent = (
  rot1: Rotation,
  rot2: Rotation,
  progression: number,
) => {
  const lastRot = getMapRotation(rot1);
  const nextRot = getMapRotation(rot2);
  const rotDiff = lastRot - nextRot;
  const otherDirDiff = Math.PI * 2 - lastRot + nextRot;
  const otherDirDiff2 = Math.PI * 2 - nextRot + lastRot;

  if (Math.abs(rotDiff) > Math.abs(otherDirDiff)) {
    return lastRot + getCenterBasedOnPercent(0, otherDirDiff, progression);
  }

  if (Math.abs(rotDiff) > Math.abs(otherDirDiff2)) {
    return lastRot - getCenterBasedOnPercent(0, otherDirDiff2, progression);
  }

  return getCenterBasedOnPercent(lastRot, nextRot, progression);
};

// this function will be used to draw the map
// quick overview of the steps:
// 1. check which resolution we want to use
// 2. calculate the start and end position of the map (relative to the viewport)
// 3. convert the position from the viewport to the coordinates of the target resolution
// 4. draw the images between the start and end position (top left and bottom right of the viewport)
//
// viewport refers to the part of the map that is visible
// canvas refers to the entire map including the outside of the viewport
export const drawMap = (
  ctx: CustomContext,
  mapManifest: MapManifest,
  mapCenter: Position,
  zIndex: number,
) => {
  let currentLayer = 0;
  ctx.startProfiler(mapManifest.meta.id);

  const {
    imageSize, mapHeight, mapWidth, minimapOffset,
  } = mapManifest.meta;

  const canvasSize = Math.min(ctx.width, ctx.height);

  // size of the map relative to the viewport
  const mapCanvasWidth = getCanvasScale(mapWidth, canvasSize, ctx.mapWidth) * ctx.getMapScale();

  const mapCanvasHeight = getCanvasScale(mapHeight, canvasSize, ctx.mapHeight)
    * ctx.getMapScale();

  let targetSize: Size;
  let nextSize: Size;

  const positionOffset: Position = {
    x: minimapOffset.x + mapCenter.x,
    y: minimapOffset.y + mapCenter.y,
  };

  ctx.drawImage({
    image: getImage(`${mapManifest.meta.imagesRoot}/background.webp`),
    size: {
      x: mapCanvasWidth,
      y: mapCanvasHeight,
    },
    position: positionOffset,
    fixedSize: true,
    zIndex: zIndex - 1,
  });

  do {
    targetSize = mapManifest.layers[currentLayer];
    nextSize = mapManifest.layers[currentLayer + 1];

    currentLayer += 1;
    // use the next layer if the both the width and height are bigger than the canvas
  } while (
    nextSize
    && nextSize.width > mapCanvasWidth
    && nextSize.height > mapCanvasHeight
  );

  // this position is relative to the current viewport. so if the point is more top left than the viewport, it will be negative
  const mapPos = ctx.mapPosToCanvasPos(positionOffset);

  // position of the viewport
  // the position is in the center and we want to have the top left and bottom right for start and end
  // the position will be the top right of the canvas relative to the top left of the map
  const viewportStartX = mapCanvasWidth / 2 - mapPos.x;
  const viewportStartY = mapCanvasHeight / 2 - mapPos.y;

  // the position of the bottom right of the canvas relative to the top left of the map (used to calculate the end position)
  const viewportEndX = ctx.width + viewportStartX;
  const viewportEndY = ctx.height + viewportStartY;

  // calculate to the size of the target viewport
  // the position converted to the size of the target canvas
  const targetStartX = (viewportStartX / mapCanvasWidth) * targetSize.width;
  const targetStartY = (viewportStartY / mapCanvasHeight) * targetSize.height;

  // calculate to the size of the target viewport
  const targetEndX = (viewportEndX / mapCanvasWidth) * targetSize.width;
  const targetEndY = (viewportEndY / mapCanvasHeight) * targetSize.height;

  // align the coordinates to the nearest image
  // there is one image each imageSize pixels so we need to go back to floor the number
  const imageStartX = Math.floor(targetStartX / imageSize) * imageSize;
  const imageStartY = Math.floor(targetStartY / imageSize) * imageSize;

  ctx.drawDebugText(mapManifest.meta.id);
  ctx.drawDebugText(`${imageStartX / imageSize}-${imageStartY / imageSize}`);
  ctx.drawDebugText(
    `${Math.floor(targetEndX / imageSize)}-${Math.floor(
      targetEndY / imageSize,
    )}`,
  );

  for (
    let x = Math.max(imageStartX, 0);
    x < Math.min(targetEndX, targetSize.width);
    x += imageSize
  ) {
    for (
      let y = Math.max(imageStartY, 0);
      y < Math.min(targetEndY, targetSize.height);
      y += imageSize
    ) {
      const imagePart = getImage(
        `${mapManifest.meta.imagesRoot}/${targetSize.width}x${targetSize.height
        }/${x / imageSize}-${y / imageSize}.webp`,
      );

      ctx.drawImage({
        image: imagePart,
        // convert the size from the target resolution to the canvas resolution
        size: {
          x: (imageSize / targetSize.width) * mapCanvasWidth,
          y: (imageSize / targetSize.height) * mapCanvasHeight,
        },
        // convert the position from the target resolution to the canvas resolution
        position: ctx.canvasPosToMapPos({
          x: (x / targetSize.width) * mapCanvasWidth - viewportStartX,
          y:
            (y / targetSize.height) * mapCanvasHeight
            - viewportStartY,
        }),
        horizontalAlign: 'left',
        verticalAlign: 'top',
        fixedSize: true,
        zIndex,
      });

      if (ctx.debug) {
        ctx.drawText({
          text: `${x / imageSize}-${y / imageSize}`,
          position: ctx.canvasPosToMapPos({
            x:
              (x / targetSize.width) * mapCanvasWidth
              - viewportStartX,
            y:
              (y / targetSize.height) * mapCanvasHeight
              - viewportStartY,
          }),
          size: 16,
          zIndex: 10,
          verticalAlign: 'top',
          horizontalAlign: 'left',
          fixedSize: true,
          color: 'black',
        });

        ctx.drawRect({
          // convert the size from the target resolution to the canvas resolution
          size: {
            x: (imageSize / targetSize.width) * mapCanvasWidth,
            y: (imageSize / targetSize.height) * mapCanvasHeight,
          },
          // convert the position from the target resolution to the canvas resolution
          position: ctx.canvasPosToMapPos({
            x: (x / targetSize.width) * mapCanvasWidth - viewportStartX,
            y:
              (y / targetSize.height) * mapCanvasHeight
              - viewportStartY,
          }),
          horizontalAlign: 'left',
          verticalAlign: 'top',
          fixedSize: true,
          zIndex: zIndex + 1,
        });
      }
    }
  }

  ctx.endProfiler();
};

export const alignPosition = (
  pos: Position,
  size: Position,
  horizontalAlign: HorizontalAlign,
  verticalAlign: VerticalAlign,
) => {
  const newPos = { ...pos };

  switch (horizontalAlign) {
    case 'left':
      break;

    case 'center':
      newPos.x -= size.x / 2;
      break;

    case 'right':
      newPos.x -= size.x;
      break;

    default:
      break;
  }

  switch (verticalAlign) {
    case 'top':
      break;

    case 'middle':
      newPos.y -= size.y / 2;
      break;

    case 'bottom':
      newPos.y -= size.y;
      break;

    default:
      break;
  }

  return newPos;
};
