import type { Position } from '../types/CustomContext';

export default (pos1: Position, pos2: Position) => {
  if (pos1.z === undefined || pos2.z === undefined) {
    return 0;
  }

  const x = (pos1.x - pos2.x) ** 2;
  const y = (pos1.y - pos2.y) ** 2;
  const z = (pos1.z - pos2.z) ** 2;

  return Math.sqrt(x + y + z);
};
