import CacheManager from './CacheManager';
import { alignPosition, getCanvasPos, getMapPos } from './map-helpers';

import type {
  CustomContextConfig,
  DrawCircleConfig,
  DrawImageConfig,
  DrawInstructions,
  DrawLineConfig,
  DrawRectConfig,
  DrawTextConfig,
  MapManifest,
  Position,
  ProfilerEntry,
  Translate,
} from '../types/CustomContext';

export default class CustomContext {
  ctx: CanvasRenderingContext2D;
  hudInstructions: Record<string, DrawInstructions[]> = {};
  mapInstructions: Record<string, DrawInstructions[]> = {};
  width = 0;
  height = 0;
  zoomLevel = 1;
  posX = 0;
  posY = 0;
  settings: CustomContextConfig;
  isTransformed = false;
  translatePos: Position = { x: 0, y: 0, z: 0 };
  savedTranslates: Record<string, Translate> = {};
  cacheManager: CacheManager = new CacheManager(this);
  manifest?: MapManifest;

  mapWidth = 300000;
  mapHeight = 300000;
  center: Position = { x: 0, y: 0 };

  debug = false;
  debugTextIndex = 0;
  debugFontSize = 20;
  frameProfiler?: ProfilerEntry;
  currentFrameProfiler?: ProfilerEntry;

  lineWidth = 5;
  color = 'black';
  fontFamily = 'Arial';

  constructor(ctx: CanvasRenderingContext2D, settings: CustomContextConfig, manifest?: MapManifest) {
    this.ctx = ctx;
    this.settings = settings;
    this.debug = settings.debug ?? false;
    this.manifest = manifest;
  }

  setZoomLevel(zoomLevel: number) {
    this.zoomLevel = Math.min(Math.max(this.settings.minZoom, zoomLevel), this.settings.maxZoom);
  }

  setCanvasSize(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  setCenter(center: Position) {
    this.center = center;

    // set the position to the center of the map so its not off center
    this.posX = center.x;
    this.posY = center.y;
  }

  startProfiler(name: string) {
    if (!this.debug) {
      return;
    }

    const currentProfile: ProfilerEntry = {
      children: [],
      start: performance.now(),
      end: 0,
      name,
      parent: this.currentFrameProfiler,
    };

    if (!this.frameProfiler) {
      this.frameProfiler = currentProfile;
    }

    this.currentFrameProfiler?.children.push(currentProfile);
    this.currentFrameProfiler = currentProfile;
  }

  endProfiler() {
    if (!this.debug) {
      return;
    }

    if (!this.currentFrameProfiler) {
      return;
    }

    this.currentFrameProfiler.end = performance.now();

    this.currentFrameProfiler = this.currentFrameProfiler.parent;
  }

  addInstruction(instruction: DrawInstructions) {
    if (instruction.asHud) {
      if (!this.hudInstructions[instruction.zIndex]) {
        this.hudInstructions[instruction.zIndex] = [];
      }

      this.hudInstructions[instruction.zIndex].push(instruction);

      return;
    }

    if (!this.mapInstructions[instruction.zIndex]) {
      this.mapInstructions[instruction.zIndex] = [];
    }

    this.mapInstructions[instruction.zIndex].push(instruction);
  }

  drawLine(config: DrawLineConfig) {
    this.addInstruction({
      type: 'line',
      width: config.width ?? this.lineWidth,
      color: config.color ?? this.color,
      points: config.positions.map((pos) => this.transformPosition(pos)),
      asHud: config.asHud ?? false,
      zIndex: config.zIndex ?? 0,
      fill: config.fill ?? false,
      fixedSize: config.asHud ? false : config.fixedSize ?? true,
      canvasOffset: {
        x: (config.canvasOffset?.x ?? 0) / this.getMapScale(),
        y: (config.canvasOffset?.y ?? 0) / this.getMapScale(),
      } ?? { x: 0, y: 0 },
    });
  }

  drawImage(config: DrawImageConfig) {
    this.addInstruction({
      type: 'image',
      image: config.image,
      position: this.transformPosition(config.position),
      size: config.size,
      horizontalAlign: config.horizontalAlign ?? 'center',
      verticalAlign: config.verticalAlign ?? 'middle',
      rotation: config.rotation ?? 0,
      asHud: config.asHud ?? false,
      zIndex: config.zIndex ?? 0,
      fill: false,
      fixedSize: config.asHud ? false : config.fixedSize ?? true,
      canvasOffset: {
        x: (config.canvasOffset?.x ?? 0) / this.getMapScale(),
        y: (config.canvasOffset?.y ?? 0) / this.getMapScale(),
      } ?? { x: 0, y: 0 },
    });
  }

  drawText(config: DrawTextConfig) {
    this.addInstruction({
      type: 'text',
      text: config.text,
      position: this.transformPosition(config.position),
      horizontalAlign: config.horizontalAlign ?? 'left',
      verticalAlign: config.verticalAlign ?? 'middle',
      fill: config.fill ?? true,
      asHud: config.asHud ?? false,
      rotation: config.rotation ?? 0,
      size: config.size,
      color: config.color ?? this.color,
      zIndex: config.zIndex ?? 0,
      fixedSize: config.asHud ? false : config.fixedSize ?? true,
      width: config.width ?? this.lineWidth,
      canvasOffset: {
        x: (config.canvasOffset?.x ?? 0) / this.getMapScale(),
        y: (config.canvasOffset?.y ?? 0) / this.getMapScale(),
      } ?? { x: 0, y: 0 },
    });
  }

  drawDebugText(text: string, color = 'black') {
    if (!this.debug) {
      return;
    }

    this.drawText({
      text,
      position: {
        x: 0,
        y: this.debugFontSize * this.debugTextIndex,
      },
      size: this.debugFontSize,
      horizontalAlign: 'left',
      verticalAlign: 'top',
      color,
      zIndex: 1000,
      fill: true,
      asHud: true,
    });

    this.debugTextIndex += 1;
  }

  drawCircle(config: DrawCircleConfig) {
    this.addInstruction({
      type: 'circle',
      position: this.transformPosition(config.position),
      radius: config.radius,
      asHud: config.asHud ?? false,
      width: config.width ?? this.lineWidth,
      color: config.color ?? this.color,
      endAngle: config.endAngle ?? 2 * Math.PI,
      startAngle: config.startAngle ?? 0,
      zIndex: config.zIndex ?? 0,
      fill: config.fill ?? false,
      horizontalAlign: config.horizontalAlign ?? 'center',
      verticalAlign: config.verticalAlign ?? 'middle',
      fixedSize: config.asHud ? false : config.fixedSize ?? true,
      canvasOffset: {
        x: (config.canvasOffset?.x ?? 0) / this.getMapScale(),
        y: (config.canvasOffset?.y ?? 0) / this.getMapScale(),
      } ?? { x: 0, y: 0 },
    });
  }

  drawRect(config: DrawRectConfig) {
    this.addInstruction({
      type: 'rect',
      fill: config.fill ?? false,
      position: this.transformPosition(config.position),
      size: config.size,
      fixedSize: config.asHud ? false : config.fixedSize ?? true,
      rotation: config.rotation ?? 0,
      asHud: config.asHud ?? false,
      color: config.color ?? this.color,
      horizontalAlign: config.horizontalAlign ?? 'center',
      verticalAlign: config.verticalAlign ?? 'middle',
      zIndex: config.zIndex ?? 0,
      lineWidth: config.lineWidth ?? this.lineWidth,
      fixedLineWidth: config.fixedLineWidth ?? true,
      canvasOffset: {
        x: (config.canvasOffset?.x ?? 0) / this.getMapScale(),
        y: (config.canvasOffset?.y ?? 0) / this.getMapScale(),
      } ?? { x: 0, y: 0 },
    });
  }

  clearScreen() {
    this.ctx.resetTransform();
    this.ctx.fillStyle = this.settings.backgroundColor;
    this.ctx.fillRect(0, 0, this.width, this.height);
    this.isTransformed = false;
    this.transformMap();
    this.debugTextIndex = 0;
  }

  private drawInstructions(instructions: DrawInstructions[]) {
    instructions.forEach((instruction) => {
      switch (instruction.type) {
        case 'line':
          instruction.points.forEach((position, index) => {
            let pos;

            if (instruction.asHud) {
              pos = position;
            } else {
              pos = getCanvasPos(position, this);
            }

            if (index === 0) {
              this.ctx.beginPath();
              this.ctx.moveTo(pos.x + instruction.canvasOffset.x, pos.y + instruction.canvasOffset.y);

              return;
            }

            this.ctx.lineTo(pos.x + instruction.canvasOffset.x, pos.y + instruction.canvasOffset.y);
          });

          if (instruction.fill) {
            this.ctx.fillStyle = instruction.color;
            this.ctx.fill();
          } else {
            this.ctx.lineWidth = instruction.width / (instruction.fixedSize ? this.getMapScale() : 1);
            this.ctx.strokeStyle = instruction.color;
            this.ctx.stroke();
          }
          break;

        case 'image': {
          const width = instruction.size.x / (instruction.fixedSize ? this.getMapScale() : 1);
          const height = instruction.size.y / (instruction.fixedSize ? this.getMapScale() : 1);

          const { position, image, rotation } = instruction;
          let thePos: Position = position;

          if (image.width === 0) {
            break;
          }

          if (!instruction.asHud) {
            thePos = getCanvasPos(position, this);
          }

          if (!rotation) {
            thePos = alignPosition(
              thePos,
              {
                x: width,
                y: height,
              },
              instruction.horizontalAlign,
              instruction.verticalAlign,
            );
          }

          if (rotation) {
            this.ctx.translate(thePos.x + instruction.canvasOffset.x, thePos.y + instruction.canvasOffset.y);
            this.ctx.rotate(rotation);

            this.ctx.drawImage(image, -width / 2, -height / 2, width, height);

            if (instruction.asHud) {
              this.clearTransform(false);
            } else {
              this.transformMap(false);
            }

            break;
          }

          this.ctx.drawImage(image, thePos.x + instruction.canvasOffset.x, thePos.y + instruction.canvasOffset.y, width, height);

          break;
        }

        case 'rect': {
          const width = instruction.size.x / (instruction.fixedSize ? this.getMapScale() : 1);
          const height = instruction.size.y / (instruction.fixedSize ? this.getMapScale() : 1);

          const { position, rotation, fill } = instruction;
          let thePos = position;

          if (!instruction.asHud) {
            thePos = getCanvasPos(position, this);
          }

          thePos = alignPosition(
            thePos,
            {
              x: width,
              y: height,
            },
            instruction.horizontalAlign,
            instruction.verticalAlign,
          );

          if (!instruction.fill) {
            this.ctx.lineWidth = instruction.lineWidth / (instruction.fixedLineWidth ? this.getMapScale() : 1);
          }

          if (rotation) {
            this.ctx.translate(thePos.x + instruction.canvasOffset.x, thePos.y + instruction.canvasOffset.y);
            this.ctx.rotate(rotation);

            if (fill) {
              this.ctx.fillRect(-width / 2, -height / 2, width, height);
            } else {
              this.ctx.strokeRect(-width / 2, -height / 2, width, height);
            }

            if (instruction.asHud) {
              this.clearTransform(false);
            } else {
              this.transformMap(false);
            }

            break;
          }

          if (fill) {
            this.ctx.fillStyle = instruction.color;
            this.ctx.fillRect(thePos.x + instruction.canvasOffset.x, thePos.y + instruction.canvasOffset.y, width, height);
          } else {
            // this.ctx.lineWidth = instruction.width;
            this.ctx.strokeStyle = instruction.color;
            this.ctx.strokeRect(thePos.x + instruction.canvasOffset.x, thePos.y + instruction.canvasOffset.y, width, height);
          }

          break;
        }

        case 'text': {
          if (instruction.verticalAlign) {
            this.ctx.textBaseline = instruction.verticalAlign;
          }

          if (instruction.horizontalAlign) {
            this.ctx.textAlign = instruction.horizontalAlign;
          }

          let pos = instruction.position;

          if (!instruction.asHud) {
            pos = getCanvasPos(pos, this);
          }

          const fontSize = instruction.size / (instruction.fixedSize ? this.getMapScale() : 1);

          this.ctx.font = `${fontSize}px ${this.fontFamily}`;

          if (instruction.rotation) {
            this.ctx.translate(pos.x + instruction.canvasOffset.x, pos.y + instruction.canvasOffset.y);
            this.ctx.rotate(instruction.rotation);

            if (instruction.fill) {
              this.ctx.fillStyle = instruction.color;
              this.ctx.fillText(instruction.text, 0, 0);
            } else {
              this.ctx.strokeStyle = instruction.color;
              this.ctx.lineWidth = instruction.width / (instruction.fixedSize ? this.getMapScale() : 1);
              this.ctx.strokeText(instruction.text, 0, 0);
            }

            if (instruction.asHud) {
              this.clearTransform(false);
            } else {
              this.transformMap();
            }

            break;
          }

          if (instruction.fill) {
            this.ctx.fillStyle = instruction.color;
            this.ctx.fillText(instruction.text, pos.x + instruction.canvasOffset.x, pos.y + instruction.canvasOffset.y);
          } else {
            this.ctx.strokeStyle = instruction.color;
            this.ctx.lineWidth = instruction.width / (instruction.fixedSize ? this.getMapScale() : 1);
            this.ctx.strokeText(instruction.text, pos.x + instruction.canvasOffset.x, pos.y + instruction.canvasOffset.y);
          }
          break;
        }

        case 'circle': {
          let pos = instruction.position;

          if (!instruction.asHud) {
            pos = getCanvasPos(pos, this);
          }

          switch (instruction.horizontalAlign) {
            case 'left':
              pos.x += instruction.radius / 2;
              break;

            case 'center':
              break;

            case 'right':
              pos.x -= instruction.radius / 2;
              break;

            default:
              break;
          }

          switch (instruction.verticalAlign) {
            case 'top':
              pos.y += instruction.radius / 2;
              break;

            case 'middle':
              break;

            case 'bottom':
              pos.y -= instruction.radius / 2;
              break;

            default:
              break;
          }

          let { radius } = instruction;

          // if (!instruction.asHud) {
          // 	radius = this.mapDistanceToCanvasDistance(radius);
          // }

          if (instruction.fixedSize) {
            radius /= this.getMapScale();
          }

          this.ctx.lineWidth = instruction.width / (instruction.fixedSize ? this.getMapScale() : 1);
          this.ctx.beginPath();
          this.ctx.arc(
            pos.x + instruction.canvasOffset.x,
            pos.y + instruction.canvasOffset.y,
            radius,
            instruction.startAngle,
            instruction.endAngle,
          );

          if (instruction.fill) {
            this.ctx.fillStyle = instruction.color;
            this.ctx.fill();
          } else {
            this.ctx.strokeStyle = instruction.color;
            this.ctx.stroke();
          }
          break;
        }

        default:
          break;
      }
    });
  }

  finish() {
    this.clearScreen();

    this.transformMap(true);

    this.startProfiler('draw');
    this.startProfiler('draw map');

    Object.keys(this.mapInstructions)
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .forEach((zIndex) => {
        this.drawInstructions(this.mapInstructions[zIndex]);
      });

    this.endProfiler();
    this.startProfiler('draw hud');

    this.clearTransform(true);

    Object.keys(this.hudInstructions)
      .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
      .forEach((zIndex) => {
        this.drawInstructions(this.hudInstructions[zIndex]);
      });

    this.endProfiler();
    this.endProfiler();
    this.endProfiler();

    this.hudInstructions = {};
    this.mapInstructions = {};

    if (this.debug && this.frameProfiler) {
      const logProfiler = (profiler: ProfilerEntry, depth = 0, parentTime = 0) => {
        const time = profiler.end - profiler.start;
        const percent = (time / parentTime) * 100;

        let color = 'green';
        let percentText = ` (${percent.toFixed(0)}%)`;

        if (percent > 50) {
          color = 'red';
        } else if (percent > 10) {
          color = 'yellow';
        }

        if (parentTime === 0) {
          percentText = '';
          color = 'green';
        }

        this.drawDebugText(`${'  '.repeat(depth)}${profiler.name}: ${time.toFixed(2)}ms${percentText}`, color);

        profiler.children.forEach((child) => {
          logProfiler(child, depth + 1, time);
        });
      };

      logProfiler(this.frameProfiler);

      this.frameProfiler = undefined;
    }
  }

  transformMap(canSkip = false) {
    if (canSkip && this.isTransformed) {
      return;
    }

    this.translatePos = { x: 0, y: 0, z: 0 };
    this.ctx.resetTransform();

    const pos = getCanvasPos(
      {
        x: this.posX,
        y: this.posY,
      },
      this,
    );

    const halfViewportWidth = this.width / this.getMapScale() / 2;
    const halfViewportHeight = this.height / this.getMapScale() / 2;

    this.ctx.scale(this.getMapScale(), this.getMapScale());
    this.ctx.translate(-pos.x + halfViewportWidth, -pos.y + halfViewportHeight);

    this.isTransformed = true;
  }

  clearTransform(canSkip: boolean) {
    if (canSkip && !this.isTransformed) {
      return;
    }

    this.translatePos = { x: 0, y: 0, z: 0 };
    this.ctx.resetTransform();
    this.isTransformed = false;
  }

  clearTranslate() {
    this.translatePos = { x: 0, y: 0, z: 0 };
  }

  move(newX: number, newY: number) {
    this.posX = newX;
    this.posY = newY;

    const halfHeight = this.mapHeight / 2;
    const halfWidth = this.mapWidth / 2;

    this.posY = Math.min(this.center.y + halfHeight, this.posY);
    this.posY = Math.max(this.center.y - halfHeight, this.posY);

    this.posX = Math.min(this.center.x + halfWidth, this.posX);
    this.posX = Math.max(this.center.x - halfWidth, this.posX);
  }

  moveRelative(x: number, y: number) {
    const size = Math.min(this.width, this.height);

    if (this.manifest?.meta.usesWeirdNewSystem) {
      this.move(
        this.posX + (x / this.getMapScale() / size) * this.mapWidth,
        this.posY + (y / this.getMapScale() / size) * this.mapHeight,
      );
    } else {
      this.move(
        this.posX - (y / this.getMapScale() / size) * this.mapWidth,
        this.posY + (x / this.getMapScale() / size) * this.mapHeight,
      );
    }
  }

  translate(pos: Position) {
    this.translatePos.x += pos.x;
    this.translatePos.y += pos.y;
  }

  rotate(angle: number) {
    this.ctx.rotate(angle);
  }

  measureText(text: string, fontSize: number, fontFamily: string | null = null) {
    this.ctx.font = `${fontSize}px ${fontFamily ?? this.fontFamily}`;

    return this.ctx.measureText(text);
  }

  setLineWidth(width: number) {
    this.lineWidth = width;
  }

  setFontFamily(family: string) {
    this.fontFamily = family;
  }

  setColor(color: string) {
    this.color = color;
  }

  canvasPosToMapPos(pos: Position) {
    const camPos = getCanvasPos(
      {
        x: this.posX,
        y: this.posY,
      },
      this,
    );

    const halfViewportWidth = this.width / this.getMapScale() / 2;
    const halfViewportHeight = this.height / this.getMapScale() / 2;

    return getMapPos(
      {
        x: camPos.x - halfViewportWidth + pos.x / this.getMapScale(),
        y: camPos.y - halfViewportHeight + pos.y / this.getMapScale(),
      },
      this,
    );
  }

  mapPosToCanvasPos(pos: Position) {
    const targetPos = getCanvasPos(pos, this);

    const camPos = getCanvasPos(
      {
        x: this.posX,
        y: this.posY,
      },
      this,
    );

    return {
      x: (targetPos.x - (camPos.x - this.width / 2 / this.getMapScale())) * this.getMapScale(),
      y: (targetPos.y - (camPos.y - this.height / 2 / this.getMapScale())) * this.getMapScale(),
    };
  }

  saveCurrentTranslate(name: string) {
    this.savedTranslates[name] = {
      x: this.translatePos.x,
      y: this.translatePos.y,
    };
  }

  loadTranslate(name: string, fallback?: Translate) {
    if (!this.savedTranslates[name] && !fallback) {
      return;
    }

    this.translatePos = this.savedTranslates[name] || fallback;
  }

  getCurrentTranslate() {
    return this.translatePos;
  }

  getMapScale() {
    return this.zoomLevel ** 5;
  }

  mapDistanceToCanvasDistance(ingameDistance: number) {
    return (ingameDistance / this.mapHeight) * this.height * this.getMapScale();
  }

  setMapSize(mapWidth: number, mapHeight: number) {
    this.mapWidth = mapWidth;
    this.mapHeight = mapHeight;
  }

  private transformPosition(pos: Position) {
    return {
      x: pos.x + this.translatePos.x,
      y: pos.y + this.translatePos.y,
    };
  }

  public getViewportBounds() {
    // we need to get the top right and bottom left corners of the viewport because the viewport is rotated

    if (this.manifest?.meta.usesWeirdNewSystem) {
      const topRight = this.canvasPosToMapPos({
        x: this.width,
        y: this.height,
      });

      const bottomLeft = this.canvasPosToMapPos({
        x: 0,
        y: 0,
      });

      return {
        topRight,
        bottomLeft,
      };
    }

    const topRight = this.canvasPosToMapPos({
      x: this.width,
      y: 0,
    });

    const bottomLeft = this.canvasPosToMapPos({
      x: 0,
      y: this.height,
    });

    return {
      topRight,
      bottomLeft,
    };
  }
}
