import type { Position } from '../types/test';
import type CustomContext from '../utils/CustomContext';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var maxHeight: number;
  // eslint-disable-next-line vars-on-top, no-var
  var minHeight: number;
  // eslint-disable-next-line vars-on-top, no-var
  var heightMapMode: boolean;
  // eslint-disable-next-line vars-on-top, no-var
  var renderPosition: boolean;
  // eslint-disable-next-line vars-on-top, no-var
  var squareSize: number;
}

export default (data: Position, ctx: CustomContext) => {
  const pos = {
    x: data.x ?? data.X,
    y: data.y ?? data.Y,
    z: data.z ?? data.Z,
  };
  ctx.setLineWidth(2);

  if (pos.z > (global.maxHeight || 100000) || pos.z < (global.minHeight || -100000)) {
    return;
  }

  if (global.heightMapMode) {
    const color = Math.round((pos.z / (global.maxHeight || 10000)) * 255);

    ctx.setColor(`rgb(${color}, ${color}, ${color})`);
  } else {
    ctx.setColor('red');
  }

  if (global.renderPosition) {
    ctx.drawText({
      text: `${pos.x}, ${pos.y}, ${pos.z}`,
      position: pos,
      verticalAlign: 'bottom',
      size: 16,
      color: 'black',
    });
  }

  if ('count' in data) {
    let toDraw = data.count?.toString() ?? '';

    if (typeof data.count === 'number') {
      toDraw = `${data.count.toFixed(2)}%`;
    }

    ctx.drawText({
      text: toDraw,
      position: pos,
      verticalAlign: 'top',
      horizontalAlign: 'center',
      canvasOffset: {
        x: 0,
        y: 10,
      },
      size: 16,
      color: 'black',
      fill: false,
      width: 2,
    });

    ctx.drawText({
      text: toDraw,
      position: pos,
      verticalAlign: 'top',
      horizontalAlign: 'center',
      canvasOffset: {
        x: 0,
        y: 10,
      },
      size: 16,
      color: 'white',
    });
  }

  ctx.drawRect({
    position: pos,
    size: {
      x: global.squareSize || 10,
      y: global.squareSize || 10,
    },
    fixedSize: true,
    fill: true,
  });
};
