/* eslint-disable no-labels */
import renderGuard from './render/renderGuard';
import renderHeightmapThingy from './render/renderHeightmapThingy';
import renderLine from './render/renderLine';
import renderObject from './render/renderObject';
import renderPlayerHit from './render/renderPlayerHit';
import renderPoiVolume from './render/renderPoiVolume';
import renderPosition from './render/renderPosition';
import renderRadioGuard from './render/renderRadioGuard';
import renderZipline from './render/renderZipline';
import initMap from './utils/map';

import './scss/index.scss';

import type { MapManifest } from './types/CustomContext';
import type {
  Circle,
  Heightmapthingy,
  Line,
  ObjectThingy,
  ObjectThingyGuard,
  ObjectThingyIg,
  ObjectThingyZipline,
  PlayerHit,
  PoiVolume,
  Position,
  RadioTowerGuard,
} from './types/test';

declare global {
  // eslint-disable-next-line vars-on-top, no-var
  var customPos: Position | string;
  // eslint-disable-next-line vars-on-top, no-var
  var customLine: Position[];
  // eslint-disable-next-line vars-on-top, no-var
  var customCircle: Circle;
  // eslint-disable-next-line vars-on-top, no-var
  var customPositions: Position[];
  // eslint-disable-next-line vars-on-top, no-var
  var typeWhitelist: string[];
}

type NormalizedPosition = { x: number; y: number; original: Position };
type AreaGroup = { topLeft: NormalizedPosition; positions: NormalizedPosition[] };

const isPosition = (pos: unknown): pos is Position => {
  if (typeof pos !== 'object' || pos === null) {
    return false;
  }

  const {
    x,
    y,
    z,
    X,
    Y,
    Z,
  } = pos as Position;

  if (typeof (x ?? X) !== 'number' || typeof (y ?? Y) !== 'number' || typeof (z ?? Z) !== 'number') {
    return false;
  }

  return true;
};

const $canvas = document.querySelector('canvas');
let positions: ObjectThingyIg[];
let floorLoots: AreaGroup[];

if (!$canvas) {
  throw new Error('no canvas found');
}

const normalizePositions = (positionss: Position[]): NormalizedPosition[] => positionss.map((pos) => ({
  x: Math.round(pos.x / 100),
  y: Math.round(pos.y / 100),
  z: Math.round((pos.z ?? 0) / 100),
  original: pos,
})).sort((a, b) => a.y - b.y || a.x - b.x);

const checkRow = (
  positionMap: Record<string, NormalizedPosition>,
  usedPositions: Record<string, boolean>,
  x: number,
  y: number,
): NormalizedPosition[] => {
  const foundPositions: NormalizedPosition[] = [];

  for (let offsetY = 0; offsetY < 3; offsetY += 1) {
    if (usedPositions[`${x},${y + offsetY}`]) {
      continue;
    }

    const pos = positionMap[`${x},${y + offsetY}`];

    if (!pos) {
      continue;
    }

    foundPositions.push(pos);
  }

  return foundPositions;
};

const checkOtherRow = (
  positionMap: Record<string, NormalizedPosition>,
  usedPositions: Record<string, boolean>,
  x: number,
  y: number,
): NormalizedPosition[] => {
  const foundPositions: NormalizedPosition[] = [];

  for (let offsetX = 0; offsetX < 3; offsetX += 1) {
    if (usedPositions[`${x + offsetX},${y}`]) {
      continue;
    }

    const pos = positionMap[`${x + offsetX},${y}`];

    if (!pos) {
      continue;
    }

    foundPositions.push(pos);
  }

  return foundPositions;
};

const groupPositionsIn3x3Areas = (normalizedPositions: NormalizedPosition[]): AreaGroup[] => {
  const positionMap: Record<string, NormalizedPosition> = {};
  const usedPositions: Record<string, boolean> = {};
  const areaGroups: AreaGroup[] = [];

  normalizedPositions.forEach((pos) => {
    positionMap[`${pos.x},${pos.y}`] = pos;
  });

  const biggestX = normalizedPositions.reduce((acc, pos) => Math.max(acc, pos.x), -Infinity);
  const biggestY = normalizedPositions.reduce((acc, pos) => Math.max(acc, pos.y), -Infinity);
  const smallestX = normalizedPositions.reduce((acc, pos) => Math.min(acc, pos.x), Infinity);
  const smallestY = normalizedPositions.reduce((acc, pos) => Math.min(acc, pos.y), Infinity);

  for (let y = smallestY; y <= biggestY; y += 1) {
    // eslint-disable-next-line no-restricted-syntax
    for (let x = smallestX; x <= biggestX; x += 1) {
      const foundPositions: NormalizedPosition[] = [];

      const firstRow = checkRow(positionMap, usedPositions, x - 1, y - 1);

      if (!firstRow.length) {
        const foundAfterLastRow = checkRow(positionMap, usedPositions, x + 2, y - 1);

        if (foundAfterLastRow.length) {
          continue;
        }
      }

      const firstOtherRow = checkOtherRow(positionMap, usedPositions, x - 1, y - 1);

      if (!firstOtherRow.length) {
        const foundAfterLastOtherRow = checkOtherRow(positionMap, usedPositions, x - 1, y + 2);

        if (foundAfterLastOtherRow.length) {
          continue;
        }
      }

      for (let offsetX = -1; offsetX < 2; offsetX += 1) {
        foundPositions.push(...checkRow(positionMap, usedPositions, x + offsetX, y - 1));
      }

      if (foundPositions.length < 3) {
        continue;
      }

      foundPositions.forEach((pos) => {
        usedPositions[`${pos.x},${pos.y}`] = true;
      });

      areaGroups.push({
        topLeft: {
          x,
          y,
          original: {
            x: x * 100,
            y: y * 100,
            z: 0,
            X: x * 100,
            Y: y * 100,
            Z: 0,
          },
        },
        positions: foundPositions,
      });
    }
  }

  return areaGroups;
};

const dataType = new URLSearchParams(window.location.search).get('dataType') || 'json';

fetch(`/positions_${new URLSearchParams(window.location.search).get('type') || 'items'}.${dataType}`).then((res) => res.text()).then((data) => {
  if (dataType === 'json') {
    positions = <ObjectThingyIg[]>JSON.parse(data);

    // positions = <ObjectThingyIg[]><unknown>[
    //   { x: -100, y: -100, z: 0 },
    //   { x: -100, y: 0, z: 0 },
    //   { x: -100, y: 100, z: 0 },
    //   { x: 0, y: -100, z: 0 },
    //   { x: 0, y: 0, z: 0 },
    //   { x: 0, y: 100, z: 0 },
    //   // { x: 100, y: -100, z: 0 },
    //   { x: 100, y: 0, z: 0 },
    //   // { x: 100, y: 100, z: 0 },

    //   { x: -500, y: -500, z: 0 },
    //   { x: 500, y: 500, z: 0 },
    // ];

    const floorPositions = <Position[]><unknown>positions.filter((a) => isPosition(a));

    floorLoots = groupPositionsIn3x3Areas(normalizePositions(floorPositions));
  } else if (dataType === 'csv') {
    const lines = data.split('\n');

    const [header, ...linesWithoutHeader] = lines;

    const headerParts = header.split(',');

    const linesWithHeader = linesWithoutHeader.map((line) => {
      const lineParts = line.split(',');

      const lineWithHeader: Record<string, string | number> = {};

      headerParts.forEach((headerPart, index) => {
        if (headerPart === 'x' || headerPart === 'y' || headerPart === 'z') {
          lineWithHeader[headerPart] = Number(lineParts[index]);

          return;
        }

        lineWithHeader[headerPart] = lineParts[index];
      });

      return lineWithHeader;
    });

    positions = <ObjectThingyIg[]><unknown>linesWithHeader;
  }
}).catch(() => console.error('failed to load positions'));

let first = true;

const { addClickArea, addMap, drawAdditionalMap } = initMap($canvas, {}, {
  maxFramerate: -1,
  mainMapManifest: `https://storage.googleapis.com/2d-replay-viewer-dev/map/map-season${new URLSearchParams(window.location.search).get('season') || 30}.json?${Date.now()}`,
  // debug: true,
}, (context, info) => {
  const ctx = context;

  if (first) {
    console.log(ctx);
    first = false;
  }

  // renderHeightmapThingy(<Heightmapthingy[]>positions, ctx, info);

  // drawAdditionalMap('season30-reload');

  // ctx.drawCircle({
  //   position: {
  //     x: info.windowWidth / 2,
  //     y: info.windowHeight / 2,
  //   },
  //   radius: 5,
  //   zIndex: 10,
  //   asHud: true,
  //   color: 'black',
  //   width: 1,
  // });

  ctx.drawLine({
    positions: [{
      x: 0,
      y: 0,
    }, {
      x: 0,
      y: 10000,
    }],
    color: 'blue',
  });

  ctx.drawLine({
    positions: [{
      x: 0,
      y: 0,
    }, {
      x: 10000,
      y: 0,
    }],
    color: 'red',
  });

  if (global.customPositions) {
    global.customPositions.forEach((pos) => {
      renderPosition(pos, ctx);
    });
  }

  if (floorLoots) {
    floorLoots.forEach((area) => {
      ctx.drawRect({
        position: area.topLeft.original,
        size: {
          x: 3,
          y: 3,
        },
        fill: true,
        color: 'blue',
        zIndex: 1,
      });
    });
  }

  if (positions) {
    positions.forEach((ok) => {
      const type = ok.Type || ok.type;

      if (!type && isPosition(ok)) {
        renderPosition(ok, ctx);

        return;
      }

      if (global.typeWhitelist && !global.typeWhitelist.includes(ok.Type || ok.type)) {
        return;
      }

      switch (type) {
        case 'BP_Athena_Environmental_ZipLine_Spline_C':
          renderZipline(<ObjectThingyZipline>ok, ctx);

          break;

        case 'FortniteGame.FortPawn:NetMulticast_Athena_BatchedDamageCues':
          renderPlayerHit(<PlayerHit>ok, ctx);

          break;

        case 'HitMarkers':
          // renderHitMarkers(ok, ctx, info);

          break;

        case 'line':
          renderLine(<Line>ok, ctx);

          break;

        case 'FortAthenaPatrolPathPointProvider':
          renderGuard(<ObjectThingyGuard>ok, ctx, info, { addClickArea });

          break;

        case 'B_MMObj_RadioTowerGuardSpawner_C':
          renderRadioGuard(<RadioTowerGuard>ok, ctx);

          break;

        case 'FortPoiVolume':
          renderPoiVolume(<PoiVolume>ok, ctx);
          break;

        default:
          renderObject(<ObjectThingy>ok, ctx, info, { addClickArea });

          break;
      }
    });
  }

  if (global.customPos) {
    if (typeof global.customPos === 'string') {
      const pos = global.customPos.match(/\((X=([0-9.-]+),Y=([0-9.-]+),Z=([0-9.-]+))\)/);

      if (pos) {
        renderPosition({
          x: Number(pos[2]),
          y: Number(pos[3]),
          z: Number(pos[4]),
          X: Number(pos[2]),
          Y: Number(pos[3]),
          Z: Number(pos[4]),
        }, ctx);
      }
    } else {
      renderPosition(global.customPos, ctx);
    }
  }

  ctx.setLineWidth(1);

  if (global.customLine) {
    ctx.drawLine({
      positions: global.customLine.map((pos) => ({
        x: Number(pos.x || pos.X),
        y: Number(pos.y || pos.Y),
      })),
    });
  }

  if (global.customCircle) {
    ctx.drawCircle({
      position: global.customCircle.position,
      radius: (global.customCircle.radius / ctx.mapWidth) * ctx.height,
      fixedSize: false,
    });
  }
});

fetch('https://storage.googleapis.com/2d-replay-viewer-dev/map/map-season30-reload.json').then((res) => res.json()).then((manifest) => {
  addMap({
    manifest: <MapManifest>manifest,
    position: {
      x: 0,
      y: 0,
    },
  });
}).catch(() => console.error('failed to load map'));
