import type { ObjectThingyZipline } from '../types/test';
import type CustomContext from '../utils/CustomContext';

/**
 * @param {*} data
 * @param {CustomContext} ctx
 * @param {*} param2
 * @returns
 */
export default (data: ObjectThingyZipline, ctx: CustomContext) => {
  const { Start, End } = data;

  if (!Start || !End) {
    return;
  }

  ctx.setLineWidth(2);

  ctx.drawLine({
    positions: [
      {
        x: Start.X,
        y: Start.Y,
      },
      {
        x: End.X,
        y: End.Y,
      },
    ],
  });
};
