import get3DDistance from '../utils/get3DDistance';

import type { HorizontalAlign } from '../types/CustomContext';
import type { PlayerHit } from '../types/test';
import type CustomContext from '../utils/CustomContext';

export default (data: PlayerHit, ctx: CustomContext) => {
  if (!data.Location || !data.playerPos) {
    return;
  }

  if (data.bIsBallistic) {
    ctx.setColor('orange');
  } else if (data.bIsCritical) {
    if (data.bIsShield) {
      ctx.setColor('lightblue');
    } else {
      ctx.setColor('yellow');
    }
  } else if (data.bIsFatal) {
    ctx.setColor('red');
  } else if (data.bIsShield) {
    ctx.setColor('blue');
  } else if (data.bIsShieldDestroyed) {
    ctx.setColor('green');
  } else {
    ctx.setColor('black');
  }

  const hitPos = data.Location;
  const shotPos = data.playerPos;

  ctx.setLineWidth(2);

  let angle = Math.atan2(hitPos.x - shotPos.x, -hitPos.y - -shotPos.y);
  let horAlign: HorizontalAlign = 'right';

  if (angle > Math.PI / 2 || angle < -Math.PI / 2) {
    angle -= Math.PI;
    horAlign = 'left';
  }

  const time = `${Math.floor(data.timeSeconds / 60)}:${Math.floor(data.timeSeconds % 60)}`;
  const distance = `${Math.round(get3DDistance(data.Location, data.playerPos) / 100)}m`;

  const text = `${time} / ${Math.round(data.Magnitude)} / ${distance}`;

  ctx.drawText({
    text,
    position: data.playerPos,
    horizontalAlign: horAlign,
    verticalAlign: 'bottom',
    rotation: angle,
    size: 0.2,
    fixedSize: false,
  });

  ctx.drawLine({
    positions: [
      hitPos,
      shotPos,
    ],
    fixedSize: true,
  });
};
