import niceifyPositions from '../utils/niceify-positions';

import type { PoiVolume } from '../types/test';
import type CustomContext from '../utils/CustomContext';

export default (
  data: PoiVolume,
  ctx: CustomContext,
) => {
  if (!data.PlayerFacingName?.LocalizedString) {
    return;
  }

  // if (!data.LocationTags[0].toLowerCase().startsWith('athena.location.poi.')) {
  //   return;
  // }

  if (data.LocationTags[0] === 'Athena.Location.POI.MMObj') {
    return;
  }

  ctx.drawLine({
    positions: [
      ...data.Vertices.map(niceifyPositions),
      niceifyPositions(data.Vertices[0]),
    ],
    color: 'black',
  });

  const thePos = {
    ...data.Position,
  };

  if (data.MapLocationTextLocationOffset) {
    thePos.X += data.MapLocationTextLocationOffset.X;
    thePos.Y += data.MapLocationTextLocationOffset.Y;
  }

  ctx.drawText({
    text: data.PlayerFacingName?.LocalizedString ?? data.LocationTags[0],
    position: niceifyPositions(thePos),
    color: 'black',
    size: 20,
    verticalAlign: 'middle',
    horizontalAlign: 'center',
  });
};
