import { getImage, getMapScale } from '../utils/map-helpers';

import type { ClickableAreaConfig, DrawFunctionPayload } from '../types/Map';
import type { ObjectThingyGuard } from '../types/test';
import type CustomContext from '../utils/CustomContext';

export default (
  data: ObjectThingyGuard,
  ctx: CustomContext,
  info: DrawFunctionPayload,
  { addClickArea }: { addClickArea: (a: ClickableAreaConfig) => void },
) => {
  const guardId = data.FiltersTags[0];

  const image = getImage(`/images/guards/${guardId}.png`);

  if (!(image instanceof HTMLImageElement)) {
    return;
  }

  const { Position } = data;

  const pos = {
    x: Position.X - 89600,
    y: Position.Y + 12800,
    z: Position.Z,
  };

  const size = 25;

  const elementSize = getMapScale(size, info.canvasWidth, info.mapWidth);

  addClickArea({
    startPos: {
      x: pos.x - elementSize / ctx.getMapScale(),
      y: pos.y - elementSize / ctx.getMapScale(),
    },
    endPos: {
      x: pos.x + elementSize / ctx.getMapScale(),
      y: pos.y + elementSize / ctx.getMapScale(),
    },
    callback: () => {
      console.log(guardId);
    },
    fixed: false,
  });

  if (image.hidden) {
    ctx.drawRect({
      position: pos,
      size: {
        x: size,
        y: size,
      },
      color: 'black',
    });

    ctx.drawRect({
      position: pos,
      size: {
        x: size - 2,
        y: size - 2,
      },
      color: 'white',
    });
  } else {
    ctx.drawImage({
      image,
      position: pos,
      size: {
        x: size,
        y: size,
      },
    });
  }

  // ctx.drawText({
  //   position: {
  //     x: pos.x,
  //     y: pos.y - size / 2 / ctx.getMapScale(),
  //   },
  //   text: guardId,
  //   color: 'black',
  //   size: 12,
  // });
};
