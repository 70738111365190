import CustomContext from './CustomContext';
import { drawMap, getEventPos } from './map-helpers';

import type { MapManifest, Position } from '../types/CustomContext';
import type {
  AdditionalMapConfig,
  ClickableAreaConfig,
  DrawFunction,
  Events,
  MapConfig,
  SliderConfig,
} from '../types/Map';

export * from './map-helpers';

const get2DDistance = (pos1: Position, pos2: Position) => {
  const x = (pos1.x - pos2.x) ** 2;
  const y = (pos1.y - pos2.y) ** 2;

  return Math.sqrt(x + y);
};

const getCenter2DPosition = (pos1: Position, pos2: Position) => ({
  x: (pos1.x - pos2.x) / 2 + pos2.x,
  y: (pos1.y - pos2.y) / 2 + pos2.y,
});

export default (
  canvas: HTMLCanvasElement,
  events: Events,
  config: MapConfig,
  drawFunction: DrawFunction,
) => {
  const context = canvas.getContext('2d');
  const timePerFrame = 1000 / (config.maxFramerate || 30);
  const { mainMapManifest } = config;
  const additionalMaps: Record<string, AdditionalMapConfig> = {};
  let lastTouchPos: TouchList;
  let canvasHeight = canvas.getBoundingClientRect().height;
  let canvasWidth = canvas.getBoundingClientRect().width;
  let isMouseDown = false;
  let clickableAreas: ClickableAreaConfig[] = [];
  let sliders: SliderConfig[] = [];
  let movedSlider = false;
  let lastFrameTime = 0;
  let hasMoved = false;
  let mapManifest: MapManifest;
  let stopDrawing = false;

  const mapUpdateFunctions: ((time: number) => void)[] = [];
  let mapUpdatesActive = false;

  const handleMapUpates = (frameTime: number) => {
    mapUpdateFunctions.forEach((updateFunc) => {
      updateFunc(frameTime);
    });

    if (!stopDrawing) {
      requestAnimationFrame(handleMapUpates);
    }
  };

  if (!context) {
    throw new Error('Could not get context');
  }

  const customCtx = new CustomContext(context, {
    maxZoom: 10,
    minZoom: 1,
    backgroundColor: '#002484',
    debug: config.debug,
  });

  fetch(mainMapManifest).then(async (manifestJson) => {
    const manifest = <MapManifest> await manifestJson.json();

    mapManifest = manifest;

    // this is the main map so we adjust the map around it
    customCtx.setCenter(manifest.meta.minimapOffset);
    customCtx.mapHeight = manifest.meta.mapHeight;
    customCtx.mapWidth = manifest.meta.mapWidth;
    customCtx.settings.backgroundColor = manifest.meta.backgroundColor;
  }).catch((a) => console.error(a));

  canvas.height = canvasHeight;
  canvas.width = canvasWidth;

  customCtx.setCanvasSize(canvasWidth, canvasHeight);

  const onMove = events.onMove || (() => { });

  const addClickArea = (info: ClickableAreaConfig) => {
    const transform = customCtx.getCurrentTranslate();

    clickableAreas.push({
      callback: info.callback,
      endPos: {
        x: info.endPos.x + transform.x,
        y: info.endPos.y + transform.y,
      },
      startPos: {
        x: info.startPos.x + transform.x,
        y: info.startPos.y + transform.y,
      },
      fixed: info.fixed,
    });
  };

  const addSlider = (info: SliderConfig) => {
    const transform = customCtx.getCurrentTranslate();

    sliders.push({
      callback: info.callback,
      endPos: {
        x: info.endPos.x + transform.x,
        y: info.endPos.y + transform.y,
      },
      startPos: {
        x: info.startPos.x + transform.x,
        y: info.startPos.y + transform.y,
      },
    });
  };

  const addMap = (manifest: AdditionalMapConfig) => {
    additionalMaps[manifest.manifest.meta.id] = manifest;
  };

  const drawAdditionalMap = (id: string) => {
    if (!additionalMaps[id]) {
      return;
    }

    const manifest = additionalMaps[id];

    drawMap(customCtx, manifest.manifest, manifest.position, -3);
  };

  const checkSlider = (event: MouseEvent) => {
    const { x: dragPosX, y: dragPosY } = getEventPos(event, canvas);

    sliders.forEach(({ startPos, endPos, callback }) => {
      const dragsInside = dragPosX > startPos.x
        && dragPosX < endPos.x
        && dragPosY > startPos.y
        && dragPosY < endPos.y;

      if (dragsInside) {
        movedSlider = true;

        const width = endPos.x - startPos.x;
        const percent = ((dragPosX - startPos.x) / width) * 100;

        callback(percent);
      }
    });
  };

  const redraw = (sinceLastFrame: number) => {
    clickableAreas = [];
    sliders = [];

    if (customCtx.width < 1 || customCtx.height < 1) {
      return;
    }

    customCtx.endProfiler(); // waiting for next frame

    customCtx.drawDebugText(`${Math.round(1000 / sinceLastFrame)} fps`);
    customCtx.drawDebugText('');

    try {
      customCtx.startProfiler('user draw');

      drawFunction(customCtx, {
        canvasWidth: Math.min(canvasHeight, canvasWidth),
        canvasHeight: Math.min(canvasHeight, canvasWidth),
        mapWidth: customCtx.mapWidth,
        mapHeight: customCtx.mapHeight,
        viewportWidth: canvasWidth / customCtx.getMapScale(),
        viewportHeight: canvasHeight / customCtx.getMapScale(),
        windowWidth: canvasWidth,
        windowHeight: canvasHeight,
        sinceLastFrame,
      });

      customCtx.endProfiler();

      customCtx.startProfiler('maps');

      if (mapManifest) {
        drawMap(customCtx, mapManifest, { x: 0, y: 0 }, -5);
      }

      customCtx.endProfiler();

      customCtx.finish();

      customCtx.startProfiler('frame');
      customCtx.startProfiler('waiting for next frame');
    } catch (err) {
      // TODO: error handling
      console.error(err);
    }
  };

  canvas.addEventListener('wheel', (event) => {
    event.preventDefault();

    const { deltaY } = event;
    const { x: mouseX, y: mouseY } = getEventPos(event, canvas);
    const { zoomLevel, posX, posY } = customCtx;

    const mapPosX = posX
      + mouseX / customCtx.getMapScale()
      - customCtx.width / customCtx.getMapScale() / 2;

    const mapPosY = posY
      + mouseY / customCtx.getMapScale()
      - customCtx.height / customCtx.getMapScale() / 2;

    customCtx.setZoomLevel(zoomLevel + deltaY * -0.003);

    const mapPosX2 = posX
      + mouseX / customCtx.getMapScale()
      - customCtx.width / customCtx.getMapScale() / 2;

    const mapPosY2 = posY
      + mouseY / customCtx.getMapScale()
      - customCtx.height / customCtx.getMapScale() / 2;

    customCtx.moveRelative(
      (mapPosX - mapPosX2) * customCtx.getMapScale(),
      (mapPosY - mapPosY2) * customCtx.getMapScale(),
    );

    onMove({
      x: customCtx.posX,
      y: customCtx.posY,
    }, true);
  });

  canvas.addEventListener('mousedown', (event) => {
    if (event.which === 1) {
      hasMoved = false;
      movedSlider = false;
      event.preventDefault();
      isMouseDown = true;
    }
  });

  canvas.addEventListener('click', (event) => {
    if (hasMoved) {
      return;
    }

    const { x: clickPosX, y: clickPosY } = getEventPos(event, canvas);

    context.resetTransform();

    const mapPos = customCtx.canvasPosToMapPos({
      x: clickPosX,
      y: clickPosY,
    });

    console.log(mapPos);

    clickableAreas.forEach(({
      startPos, endPos, callback, fixed,
    }) => {
      let clicked;

      if (fixed) {
        clicked = clickPosX > startPos.x
          && clickPosX < endPos.x
          && clickPosY > startPos.y
          && clickPosY < endPos.y;
      } else {
        clicked = mapPos.x > startPos.x
          && mapPos.x < endPos.x
          && mapPos.y > startPos.y
          && mapPos.y < endPos.y;
      }

      if (clicked) {
        callback();
      }
    });
  });

  const mouseUpHandler = (event: MouseEvent) => {
    if (event.which === 1) {
      event.preventDefault();
      isMouseDown = false;

      if (!hasMoved) {
        checkSlider(event);
      }
    }
  };

  document.body.addEventListener('mouseup', mouseUpHandler);

  const mouseMoveHandler = (event: MouseEvent) => {
    if (isMouseDown) {
      const { movementX, movementY } = event;

      if (!hasMoved || movedSlider) {
        checkSlider(event);

        if (movedSlider) {
          return;
        }
      }
      hasMoved = true;

      customCtx.moveRelative(-movementX, -movementY);

      onMove({
        x: customCtx.posX,
        y: customCtx.posY,
      }, false);
    }
  };

  document.body.addEventListener('mousemove', mouseMoveHandler);

  canvas.addEventListener(
    'touchmove',
    (event) => {
      if (lastTouchPos?.length) {
        event.preventDefault();

        if (lastTouchPos.length === 1) {
          // Move with one touch
          const moveX = lastTouchPos[0].screenX
            - event.targetTouches[0].screenX;

          const moveY = lastTouchPos[0].screenY
            - event.targetTouches[0].screenY;

          customCtx.moveRelative(moveX, moveY);

          onMove({
            x: customCtx.posX,
            y: customCtx.posY,
          }, false);
        } else if (lastTouchPos.length === 2) {
          // Move/Scale with two touches
          const currentTouchDistance = get2DDistance(
            getEventPos(event.targetTouches[0], canvas),
            getEventPos(event.targetTouches[1], canvas),
          );

          const lastTouchDistance = get2DDistance(
            getEventPos(lastTouchPos[0], canvas),
            getEventPos(lastTouchPos[1], canvas),
          );

          const posBetweenTouch = getCenter2DPosition(
            getEventPos(event.targetTouches[0], canvas),
            getEventPos(event.targetTouches[1], canvas),
          );

          const lastPosBetweenTouch = getCenter2DPosition(
            getEventPos(lastTouchPos[0], canvas),
            getEventPos(lastTouchPos[1], canvas),
          );

          const { zoomLevel, posX, posY } = customCtx;

          const mapScale = customCtx.getMapScale();

          const mapPosX = posX
            + lastPosBetweenTouch.x / mapScale
            - customCtx.width / mapScale / 2;

          const mapPosY = posY
            + lastPosBetweenTouch.y / mapScale
            - customCtx.height / mapScale / 2;

          customCtx.setZoomLevel(
            zoomLevel
            - (lastTouchDistance - currentTouchDistance) / 200,
          );

          const mapScaleAfter = customCtx.getMapScale();

          const mapPosX2 = posX
            + posBetweenTouch.x / mapScaleAfter
            - customCtx.width / mapScaleAfter / 2;

          const mapPosY2 = posY
            + posBetweenTouch.y / mapScaleAfter
            - customCtx.height / mapScaleAfter / 2;

          customCtx.moveRelative(
            (mapPosX - mapPosX2) * mapScaleAfter,
            (mapPosY - mapPosY2) * mapScaleAfter,
          );
        }
      }

      lastTouchPos = event.targetTouches;
    },
    { passive: false },
  );

  canvas.addEventListener('touchstart', (event) => {
    lastTouchPos = event.targetTouches;
  });

  const touchEndHandler = (event: TouchEvent) => {
    lastTouchPos = event.targetTouches;
  };

  document.body.addEventListener('touchend', touchEndHandler);

  const handleResize = () => {
    canvasHeight = canvas.getBoundingClientRect().height;
    canvasWidth = canvas.getBoundingClientRect().width;
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;
    customCtx.setCanvasSize(canvasWidth, canvasHeight);
    redraw(0);
  };

  window.addEventListener('resize', handleResize);

  const updateMap = (frameTime: number) => {
    const sinceLastFrame = frameTime - lastFrameTime;

    if (sinceLastFrame > timePerFrame) {
      redraw(sinceLastFrame);

      lastFrameTime = frameTime;
    }
  };

  mapUpdateFunctions.push(updateMap);

  if (!mapUpdatesActive && !stopDrawing) {
    requestAnimationFrame(handleMapUpates);

    mapUpdatesActive = true;
  }

  const observer = new MutationObserver(() => {
    if (!document.body.contains(canvas)) {
      observer.disconnect();
      stopDrawing = true;

      document.body.removeEventListener('mouseup', mouseUpHandler);
      document.body.removeEventListener('mousemove', mouseMoveHandler);
      document.body.removeEventListener('touchend', touchEndHandler);
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });

  return {
    redraw,
    addClickArea,
    addSlider,
    addMap,
    drawAdditionalMap,
  };
};
