import type { DrawInstructions, Size, Position } from '../types/CustomContext';
import type CustomContext from './CustomContext';

export interface CacheObject {
  id: string;
  instruction: DrawInstructions;
  parent: CacheEntry;
}

export interface CacheEntry {
  image?: HTMLCanvasElement;
  zIndex: number;
  needsUpdate: boolean;
  position: Position;
  ingameSize: Size;
  canvasSize: Size;
  /** stores the locations the image uses. the location is in grid coordinates */
  entries: CacheObject[];
}

/**
 * The cache manager keeps track of the different objects that are cached for each layer
 * Each layer has its own grid of cached objects and each part of the grid is its own image
 * Images need to be updated when the entry assigned to that image updates and is in view by the viewport
 * The cache manager will keep track of everything inside the map so things outside the map will not be drawn
 */
export default class CacheManager {
  /** all the objects cached indexed by layer, gridX, gridY */
  private grid: Record<string, Record<number, Record<number, CacheEntry>>> = {};
  /** all the objects cached indexed by its id */
  private objects: Record<string, CacheObject> = {};
  /** cache entries by gridX, gridY */
  /** the size of each entry in ingame coordinates */
  private entrySize: Size = {
    width: 15000,
    height: 15000,
  };

  private ctx: CustomContext;

  public constructor(ctx: CustomContext) {
    this.ctx = ctx;
  }

  public addInstruction(layer: string, instruction: DrawInstructions) {
    if (instruction.asHud) {
      throw new Error('Instructions that are hud are not supported yet');
    }

    if (!('position' in instruction)) {
      throw new Error('Instructions that dont have a position are not supported yet');
    }

    const gridX = Math.floor(instruction.position.x / this.entrySize.width);
    const gridY = Math.floor(instruction.position.y / this.entrySize.height);

    const cacheEntry = this.getOrCreateCacheEntry(layer, gridX, gridY, instruction.zIndex);

    let id;
    let offset = 0;

    do {
      offset += 1;
      id = `${layer}_${gridX}_${gridY}_${cacheEntry.entries.length}_${offset}`;
    } while (this.objects[id]);

    const cacheObject: CacheObject = {
      id,
      instruction,
      parent: cacheEntry,
    };

    cacheEntry.entries.push(cacheObject);
    this.objects[id] = cacheObject;
  }

  private getOrCreateCacheEntry(layer: string, gridX: number, gridY: number, zIndex: number): CacheEntry {
    if (this.grid[layer]?.[gridX]?.[gridY]) {
      return this.grid[layer][gridX][gridY];
    }

    const entry: CacheEntry = {
      zIndex,
      needsUpdate: true,
      position: {
        x: gridX * this.entrySize.width,
        y: gridY * this.entrySize.height,
      },
      ingameSize: {
        width: this.entrySize.width,
        height: this.entrySize.height,
      },
      canvasSize: {
        width: this.entrySize.width,
        height: this.entrySize.height,
      },
      entries: [],
    };

    const layerGrid = this.grid[layer];

    if (!layerGrid) {
      this.grid[layer] = {};
    }

    const grid = this.grid[layer][gridX];

    if (!grid) {
      this.grid[layer][gridX] = {};
    }

    this.grid[layer][gridX][gridY] = entry;

    return entry;
  }
}
