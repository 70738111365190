import type { Line } from '../types/test';
import type CustomContext from '../utils/CustomContext';

export default (data: Line, ctx: CustomContext) => {
  const { positions } = data;

  ctx.setLineWidth(2);

  ctx.drawLine({
    positions: positions.map((position) => ({
      x: position.x || position.X,
      y: position.y || position.Y,
    })),
  });
};
